@import '~antd/dist/antd.css';

.logo {
    margin: 25px 0;
    background: rgba(255, 255, 255, 0.2);
}

.logo > img {
    width: 70%;
}

.ant-layout-header {
    padding: 0 20px;
}

.site-layout-sub-header-background {
    background: #fff;
}

.site-layout-background {
    background: #fff;
}


.showcase {
    width: 100%;
    height: 100%;
    align-self: center;
    display: flex;
    border: 0;
}

.showcase_preview .showcase_container {
    height: 600px;
}

.showcase_container {
    height: 100%;
    position: relative;
}

.guid {
    position: fixed;
    right: -49px;
    bottom: 0;
    width: 368px;
    transition: transform .1s;
    transform-origin: bottom right;
    cursor: pointer;
}

.guid.scale {
    transform: scale(2) ;
    z-index: 999999999;
}

.guid_content {
    position: fixed;
    right: 37px;
    bottom: 220px;
}

.guid_content.fullscreen {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 25px;
    backdrop-filter: blur(5px);
}

.guid_content.fullscreen .guid_content_box {
    width: 100%;
    height: 100%;
    background: none;
}

.guid_content_box {
    padding: 10px;
    background-color: black;
    border-radius: 8px;
    width: 366px;
    height: 215px;
}

.guid_content_box.hide_guid_content_box {
    display: none;
}

.guid_content_box img.image_guide {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.guid_content .hide_video_guid {
    right: 0;
    position: absolute;
    bottom: -55px;
    z-index: 999999999;
}

.guid_content iframe {
    width: 100%;
}

.triangle {
    width: 0;
    height: 0;
    position: fixed;
    bottom: 0;
    right: 0;
    border-style: solid;
    border-width: 0 0 170px 415px;
    border-color: transparent transparent #ffffff transparent;
}

.react_modal {
    background-color: #fff;
    border: 0;
    border-radius: 2px;
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
    overflow: visible;

    position: absolute;
    inset: 40px;
    background: #fff;

    outline: none;
    padding: 20px;
}

.react_modal .close {
    position: absolute;
    top: -25px;
    right: -25px;
}

.viewer.guid_grid {
    display: grid;
    grid-template-rows: auto 70px;
    height: 100%;
    width: 100%;
    position: fixed;
}

.next-btn.animate__heartBeat {
    /*--animate-duration: 1000ms;*/
    --animate-repeat: 10;
}

.intro_modal .intro_image {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.next-animation {
    animation: color-change 1s infinite;
}

@keyframes color-change {
    0% {  }
    50% {
        color: #40a9ff;
        border-color: #40a9ff;
    }
    100% { }
}

.fullscreen_guid {
    position: absolute;
    right: -15px;
    top: -15px;
    transition: none;
}

.guid_content.fullscreen .fullscreen_guid {
    position: absolute;
    right: 10px;
    top: 10px;
}

.customOverlay {
    background: rgba(36, 123, 160, 0.7);
}
.react-responsive-modal-modal.customModal {
    position: absolute;
    top: 15px;
    bottom: 15px;
    height: auto;
    min-width: 800px;
    margin: 0 auto;
    left: 10px;
    right: 10px;
    max-width: 1700px;
}

.ant-timeline p {
    margin: 0;
}